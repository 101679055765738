import type {
  FlowEditorSDK,
  FlowPlatformOptions,
} from '@wix/yoshi-flow-editor';
import { WidgetInstallationType } from '@wix/platform-editor-sdk';
import rootWidget from '@/components/DonationForm/.component.json';
import { initLocaleKeys } from '@/common/utils/locale';
import { addThankYouPage } from '@/editor-app/editor-ready/first-install/addthankYouPage';
import { RootStore } from '@/components/DonationForm/types';
import { formWidgetPresetsIds } from '@/common/constants/app';
import { FedopsInteractions } from '@/components/DonationForm/services/fedopsInteraction';

export async function firstInstallHandler(
  editorSDK: FlowEditorSDK,
  t: ReturnType<typeof initLocaleKeys>,
  options: FlowPlatformOptions,
  flowAPI: RootStore['controllerParams']['flowAPI'],
): Promise<void> {
  // https://github.com/wix-private/restaurants-orders-blocks/blob/master/packages/restaurants-orders-bob/src/editor/editor.utils.ts#L72
  await editorSDK.editor.openProgressBar('_token', {
    title: t.wixDonations.installationPopup.loader.header(),
    totalSteps: 3,
    currentStep: 0,
  });
  // @ts-expect-error
  const isRTL = flowAPI.environment.isSiteLanguageRTL;

  flowAPI.panoramaClient?.transaction('add-donation-form-widget').start();
  flowAPI.fedops.interactionStarted(FedopsInteractions.AddDonationFormWidget);
  await addDonationFormWidget(editorSDK, isRTL, options);
  flowAPI.fedops.interactionEnded(FedopsInteractions.AddDonationFormWidget);
  flowAPI.panoramaClient?.transaction('add-donation-form-widget').finish();

  await editorSDK.editor.updateProgressBar('_token', {
    currentStep: 1,
    stepTitle: t.wixDonations.installationPopup.loader.step2(),
  });

  flowAPI.panoramaClient?.transaction('add-thank-you-page').start();
  flowAPI.fedops.interactionStarted(FedopsInteractions.AddThankYouPage);
  await addThankYouPage(editorSDK, options, t, isRTL, flowAPI);
  flowAPI.fedops.interactionEnded(FedopsInteractions.AddThankYouPage);
  flowAPI.panoramaClient?.transaction('add-thank-you-page').finish();

  await editorSDK.editor.updateProgressBar('_token', {
    currentStep: 2,
    stepTitle: t.wixDonations.installationPopup.loader.step2(),
  });
}

const addDonationFormWidget = async (
  editorSDK: FlowEditorSDK,
  isRTL: boolean,
  options: FlowPlatformOptions,
) => {
  await editorSDK.application.appStudioWidgets.addWidget('', {
    widgetId: rootWidget.id,
    installationType: 'closed' as WidgetInstallationType,
    scopedPresets: {
      mobile: {
        layout: isRTL ? formWidgetPresetsIds.RTL : formWidgetPresetsIds.LTR,
        style: isRTL ? formWidgetPresetsIds.RTL : formWidgetPresetsIds.LTR,
      },
      desktop: {
        layout: isRTL ? formWidgetPresetsIds.RTL : formWidgetPresetsIds.LTR,
        style: isRTL ? formWidgetPresetsIds.RTL : formWidgetPresetsIds.LTR,
      },
    },
    layout: {
      height: 500,
      width: 500,
      x: 240,
      y: 100,
    },
    layouts: {
      componentLayout: {
        type: 'ComponentLayout',
        maxWidth: {
          type: 'percentage',
          value: 100,
        },
        minHeight: {
          type: 'px',
          value: 280,
        },
        hidden: false,
        height: {
          type: 'auto',
        },
        width: {
          type: 'px',
          value: 500,
        },
      },
      itemLayout: {
        alignSelf: 'center',
        margins: {
          top: {
            type: 'px',
            value: 20,
          },
          bottom: {
            type: 'px',
            value: 20,
          },
        },
        gridArea: {
          rowStart: 1,
          rowEnd: 2,
          columnStart: 1,
          columnEnd: 2,
        },
        id: '',
        justifySelf: 'center',
        type: 'GridItemLayout',
      },
    },
    overriddenData: [
      {
        itemType: 'connections',
        dataItem: {
          type: 'ConnectionList',
          items: [
            {
              type: 'WixCodeConnectionItem',
              role: 'donationForm1',
            },
          ],
        },
        compId: 'comp-ldw685nw',
        isMobile: false,
      },
    ],
  });
};
