export const enum PanelNames {
  settings = 'Settings',
  chooseCampaign = 'ChooseCampaign',
  donationFormSettings = 'DonationFormSettings',
  donationFormLayout = 'DonationFormLayout',
  frequencySettings = 'Frequency Settings',
  amountSettings = 'Amount Settings',
  donorNoteSettings = 'Donor Note Settings',
}

export const enum PlatformPanelActionIds {
  DONATION_ADD_PANEL_DEEPLINK = 'DONATION_ADD_PANEL_DEEPLINK',
  DONATION_SHOW_ON_PAGE = 'DONATION_SHOW_ON_PAGE',
  DONATION_FORM_DESIGN = 'DONATION_FORM_DESIGN',
}
