import { SubscriptionFrequency } from '@wix/ambassador-ecom-v1-order/types';

export enum ComponentIds {
  typWrapper = '#typWrapper',
  typTitle = '#txtTitle',
  typLine2 = '#txtLine2',
  typLine3 = '#txtLine3',
}

export type TypInitialData = {
  objectType: string | undefined;
  id: string | undefined | null;
  campaignId: string | undefined | null;
  checkoutId: string | undefined | null;
  firstName: string | undefined | null;
  lastName: string | undefined | null;
  amount: string | undefined | null;
  currency: string | undefined | null;
  frequency: SubscriptionFrequency;
  orderNumber?: string | undefined | null;
  paymentStatus?: string | undefined | null;
  catalogAppId: string | undefined | null;
};
