export const enum ActionIds {
  CHOOSE_CAMPAIGN = 'CHOOSE_CAMPAIGN',
  MANAGE_CAMPAIGN = 'MANAGE_CAMPAIGN',
  DONATION_FORM_ELEMENTS = 'DONATION_FORM_ELEMENTS',
  DONATION_FORM_SETTINGS = 'DONATION_FORM_SETTINGS',
  DONATION_FORM_LAYOUT = 'DONATION_FORM_LAYOUT',
  FREQUENCY_SETTINGS = 'FREQUENCY_SETTINGS',
  AMOUNT_SETTINGS = 'AMOUNT_SETTINGS',
  DONOR_NOTE_SETTINGS = 'DONOR_NOTE_SETTINGS',
}

export const enum helpIds {
  DONATION_FORM = '80bbdb20-2818-4fdf-9218-cbe6fd1247ec',
  DONATION_FORM_DESIGN = '1d201a67-f5eb-48e2-bea3-2446662537b0',
  CHOOSE_CAMPAIGN = 'dcc48574-8ecf-493b-a415-228aefc505de',
  FORM_TITLE = '7c4dfdf9-8e75-43ce-9a4d-eccb78c74696',
  FORM_DESCRIPTION = '8a68ad2f-741c-4d6f-82a9-02fa3915a40b',
  FREQUENCY = 'd7346e6b-d921-40ca-ac8b-0af98e75ed97',
  AMOUNT = '99203d40-4389-40a6-a264-cff037f6cd75',
  DONOR_NOTE = '1b944c03-ae78-4d81-a538-a8f535b34f3f',
  DONATE_BUTTON = 'dcce8abf-7150-473b-94f1-a5ecdb64a1ae',
}
