export const ComponentIds = {
  RootBox: '#box1',
  Box: '#box2',
  LabelBox: '#labelBox',
  TextBox: '#textBox1',
  Label: '#noteTitle',
  OptionalLabel: '#text3',
  CharCounter: '#text2',
} as const;

export enum CommentWidgetSettings {
  showTitle = 'shouldShowTitle',
  labelText = 'label',
  placeholderText = 'placeholder',
  maxLength = 'maxLength',
}

export const SettingToBiElement = {
  [CommentWidgetSettings.showTitle]: 'show title',
  [CommentWidgetSettings.labelText]: 'title text',
  [CommentWidgetSettings.placeholderText]: 'placeholder text',
  [CommentWidgetSettings.maxLength]: 'maximum characters',
};
