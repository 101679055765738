import { getRole } from '@/common/utils/getRole';

export const setComponentsAsUnselectable = (
  widgetBuilder,
  componentsIds: string[],
) => {
  componentsIds.forEach((component) => {
    widgetBuilder.configureConnectedComponents(
      getRole(component),
      (innerWidgetBuilder) => {
        innerWidgetBuilder.behavior().set({
          closed: {
            selectable: false,
            hideFromHierarchy: true,
          },
        });
      },
    );
  });
};
