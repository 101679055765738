import { WIX_ECOM } from '@wix/app-definition-ids';

export const ECOM_APP_DEF_ID = WIX_ECOM;

export const DONATIONS_APP_DEF_ID = '333b456e-dd48-4d6b-b32b-9fd48d74e163';

export const formWidgetPresetsIds = {
  RTL: 'variants-lrngx529',
  LTR: 'variants-ldw685no1',
};

export const typWidgetPresetsIds = {
  RTL: 'variants-lrnhcuk0',
  LTR: 'variants-loya4pjw',
};
