import {
  WidgetPropertyType,
  createBlocksModel,
} from '@wix/yoshi-flow-editor/blocks';

export default createBlocksModel({
  widgetName: 'AmountWidget',
  props: {
    options: {
      type: undefined as any,
      defaultValue: [],
    },
    defaultValue: {
      type: WidgetPropertyType.STRING,
      defaultValue: undefined,
    },
    minCustomAmount: {
      type: undefined as any,
      defaultValue: undefined,
    },
    maxCustomAmount: {
      type: undefined as any,
      defaultValue: undefined,
    },
    customAmountEnabled: {
      type: WidgetPropertyType.BOOLEAN,
      defaultValue: false,
    },
    label: {
      type: WidgetPropertyType.STRING,
      defaultValue: '',
    },
    shouldShowLabel: {
      type: WidgetPropertyType.BOOLEAN,
      defaultValue: true,
    },
  },
  methods: {
    focus: {},
    isValid: {},
    getValue: {},
    reset: {},
  },
  events: {
    change: {},
  },
});
