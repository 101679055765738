import {
  ContextParams,
  EditorSDK,
  EditorType,
  EventType,
} from '@wix/platform-editor-sdk';
import { ActionIds } from '@/common/constants/gfpp';
import { openShowHidePanel } from '@/editor-app/panels/openShowHidePanel';
import { PanelsApiFactory } from '@wix/blocks-widget-services/panels';
import blockWidgetDescriptor from '../../../../blocks-widget-descriptor.json';
import { ComponentRef, FlowAPI } from '@wix/yoshi-flow-editor';
import donationForm from '@/components/DonationForm/.component.json';
import { PanelNames } from '@/common/constants/panels';
import { initLocaleKeys } from '@/common/utils/locale';

export const registerGfppEvents = async (
  editorSDK: EditorSDK,
  editorType: EditorType,
  flowAPI: FlowAPI,
  options: ContextParams,
) => {
  const panelsApi = await new PanelsApiFactory().createPanelsApi({
    editorSDK,
    editorType,
    essentials: options.essentials,
  });

  const openBlocksPanelWrapper = (
    panelName: string,
    componentRef: ComponentRef,
    title?: string,
  ) =>
    panelsApi.openBlocksPanel(panelName, componentRef, {
      overrides: {
        revision: blockWidgetDescriptor[donationForm.id].revision.toString(),
        title,
      },
    });

  const t = initLocaleKeys(flowAPI.translations.i18n);

  const registerWidgetGfppEvents = editorSDK.addEventListener(
    EventType.widgetGfppClicked,
    (event) => {
      const { id, componentRef } = event.detail;
      switch (id) {
        case ActionIds.DONATION_FORM_ELEMENTS:
          void openShowHidePanel(editorSDK, componentRef, flowAPI);
          break;
        case ActionIds.FREQUENCY_SETTINGS:
          void openBlocksPanelWrapper(
            PanelNames.frequencySettings,
            componentRef,
            t.donationForm.frequency.settings.panelHeader(),
          );
          break;
        case ActionIds.AMOUNT_SETTINGS:
          void openBlocksPanelWrapper(
            PanelNames.amountSettings,
            componentRef,
            t.donationForm.amount.settings.panelHeader(),
          );
          break;
        case ActionIds.DONOR_NOTE_SETTINGS:
          void openBlocksPanelWrapper(
            PanelNames.donorNoteSettings,
            componentRef,
            t.donationForm.donorNote.settings.panelHeader(),
          );
          break;
        case ActionIds.DONATION_FORM_SETTINGS:
          void openBlocksPanelWrapper(
            PanelNames.donationFormSettings,
            componentRef,
            t.donationForm.formWidget.settings.panelHeader(),
          );
          break;
        case ActionIds.DONATION_FORM_LAYOUT:
          void openBlocksPanelWrapper(
            PanelNames.donationFormLayout,
            componentRef,
            t.donationForm.formWidget.layout.panelHeader(),
          );
      }
    },
  );

  await Promise.all([registerWidgetGfppEvents]);
};
