import type { GetWidgetManifestFn } from '@wix/yoshi-flow-editor/blocks';
import { initLocaleKeys } from '@/common/utils/locale';
import { getRole } from '@/common/utils/getRole';
import { ComponentIds } from '@/components/ThankYouPage/constants';

export const getWidgetManifest: GetWidgetManifestFn = (
  widgetBuilder,
  editorSDK,
  flowAPI,
) => {
  const t = initLocaleKeys(flowAPI.translations.i18n);

  widgetBuilder.set({
    displayName: t.thankYouPage.thankYouPageWidget.displayName(),
    nickname: 'ThankYouPageWidget',
  });

  widgetBuilder.gfpp().set('add', { behavior: 'HIDE' }).set('help', { id: '' });
  widgetBuilder.behavior().set({ removable: false });
  setTextsAsUneditable(widgetBuilder, [
    ComponentIds.typTitle,
    ComponentIds.typLine2,
    ComponentIds.typLine3,
  ]);
};

const setTextsAsUneditable = (widgetBuilder, textComponents: string[]) => {
  textComponents.forEach((textComponent) => {
    widgetBuilder.configureConnectedComponents(
      getRole(textComponent),
      (innerWidgetBuilder) => {
        innerWidgetBuilder
          .behavior()
          .set({ dataEditOptions: 'TEXT_STYLE_ONLY' });
      },
    );
  });
};
