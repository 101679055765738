export const ComponentIds = {
  RootBox: '#box1',
  ListBox: '#radioGroupBox',
  Repeater: '#presetsRepeater',
  RepeaterItem: '#selectableContainer',
  RepeaterItemInput: '#selectableContainerInput',
  Label: '#frequencyTitle',
  multiStateItem: '#msbItem',
  itemTextSelected: '#itemTextSelected',
  itemTextDefault: '#itemTextDefault',
  itemContainerSelected: '#selectedContainer',
  itemContainerSelectedState: '#Selected',
  itemContainerDefaultState: '#Default',
  itemContainerDefault: '#defaultContainer',
} as const;

export enum FrequencyWidgetSettings {
  showTitle = 'shouldShowTitle',
  labelText = 'label',
  defaultValue = 'defaultValue',
}

export const SettingToBiElement = {
  [FrequencyWidgetSettings.showTitle]: 'show title',
  [FrequencyWidgetSettings.labelText]: 'title text',
  [FrequencyWidgetSettings.defaultValue]: 'default frequency',
};
