import {
  ConnectedComponentsBuilder,
  GetAppManifestFn,
} from '@wix/yoshi-flow-editor';
import { PanelsApiFactory } from '@wix/blocks-widget-services/panels';
import blockWidgetDescriptor from '../../../blocks-widget-descriptor.json';
import rootWidget from '@/components/DonationForm/.component.json';
import { PanelNames, PlatformPanelActionIds } from '@/common/constants/panels';
import {
  ComponentIds,
  EmptyStateComponentIds,
} from '@/components/DonationForm/constants';
import { ActionIds, helpIds } from '@/common/constants/gfpp';
import { initLocaleKeys } from '@/common/utils/locale';
import { setComponentsAsUnselectable } from '@/common/utils/setComponentsAsUnselectable';
import { donationFormDesignBuilderFn } from '@/editor-app/manifest/manifest.design';
import { clickManageCampaigns } from '@wix/bi-logger-wixdonations/v2';
import { openManageCampaignsDashboard } from '@/editor-app/manifest/manifestUtils/OpenManageCampaignsDashboard';

export const getAppManifest: GetAppManifestFn = async (
  options,
  editorSDK,
  contextParams,
  flowAPI,
) => {
  const { appManifestBuilder } = options;
  const panelsApi = await new PanelsApiFactory().createPanelsApi({
    editorSDK,
    editorType: contextParams.origin.type,
    essentials: contextParams.essentials,
  });
  const t = initLocaleKeys(flowAPI.translations.i18n);
  const manifest = appManifestBuilder
    .configureWidget(rootWidget.controllerId, (controllerBuilder) => {
      controllerBuilder
        .set({
          displayName: t.donationForm.formWidget.displayName(),
          nickname: 'DonationFormWidget',
        })
        .gfpp()
        .set('mainAction1', {
          label: t.donationForm.formWidget.gfpp.chooseCampaign(),
          onClick: (event) => {
            const { componentRef } = event.detail;
            void panelsApi.openBlocksPanel(
              PanelNames.chooseCampaign,
              componentRef as any,
              {
                overrides: {
                  revision:
                    blockWidgetDescriptor[rootWidget.id].revision.toString(),
                  title: t.donationForm.formWidget.chooseCampaign.panelHeader(),
                },
              },
            );
          },
        })
        .set('mainAction2', {
          label: t.donationForm.formWidget.gfpp.manageCampaigns(),
          onClick: () => {
            flowAPI.bi!.report(
              clickManageCampaigns({
                origin: 'gfpp',
              }),
            );
            return openManageCampaignsDashboard(editorSDK);
          },
        })
        .set('help', { id: helpIds.DONATION_FORM })
        .set('add', {
          actionId: ActionIds.DONATION_FORM_ELEMENTS,
        })
        .set('layout', { actionId: ActionIds.DONATION_FORM_LAYOUT })
        .set('settings', { actionId: ActionIds.DONATION_FORM_SETTINGS })
        .set('animation', { behavior: 'HIDE' })
        .set('stretch', { behavior: 'HIDE' })
        .set('connect', { behavior: 'HIDE' });

      controllerBuilder
        .gfpp('mobile')
        .set('mainAction1', {
          label: t.donationForm.formWidget.gfpp.chooseCampaign(),
          onClick: (event) => {
            const { componentRef } = event.detail;
            void panelsApi.openBlocksPanel(
              PanelNames.chooseCampaign,
              componentRef as any,
              {
                overrides: {
                  revision:
                    blockWidgetDescriptor[rootWidget.id].revision.toString(),
                  title: t.donationForm.formWidget.chooseCampaign.panelHeader(),
                },
              },
            );
          },
        })
        .set('mainAction2', {
          label: t.donationForm.formWidget.gfpp.manageCampaigns(),
          onClick: () => {
            flowAPI.bi!.report(
              clickManageCampaigns({
                origin: 'gfpp',
              }),
            );
            return openManageCampaignsDashboard(editorSDK);
          },
        })
        .set('help', { id: helpIds.DONATION_FORM })
        .set('layout', { actionId: ActionIds.DONATION_FORM_LAYOUT })
        .set('settings', { actionId: ActionIds.DONATION_FORM_SETTINGS })
        .set('animation', { behavior: 'HIDE' });

      controllerBuilder.configureConnectedComponents(
        getRole(ComponentIds.Title),
        (innerWidgetBuilder) => {
          hideSeoA11y(innerWidgetBuilder);
          innerWidgetBuilder.set({
            displayName: t.donationForm.title.displayName(),
          });
          innerWidgetBuilder
            .gfpp()
            .set('connect', { behavior: 'HIDE' })
            .set('link', { behavior: 'HIDE' })
            .set('help', { id: helpIds.FORM_TITLE });
        },
      );
      controllerBuilder.configureConnectedComponents(
        getRole(ComponentIds.Description),
        (innerWidgetBuilder) => {
          hideSeoA11y(innerWidgetBuilder);
          innerWidgetBuilder.set({
            displayName: t.donationForm.description.displayName(),
          });
          innerWidgetBuilder
            .gfpp()
            .set('connect', { behavior: 'HIDE' })
            .set('link', { behavior: 'HIDE' })
            .set('help', { id: helpIds.FORM_DESCRIPTION });
        },
      );
      controllerBuilder.configureConnectedComponents(
        getRole(ComponentIds.MultiStateBox),
        (innerWidgetBuilder) => {
          innerWidgetBuilder.behavior().set({ preventHide: true });
          innerWidgetBuilder.behavior().set({
            closed: {
              selectable: false,
            },
          });
        },
      );
      setComponentsAsUnselectable(controllerBuilder, [
        ComponentIds.DonateButtonContainer,
        ComponentIds.PremiumErrorIcon,
        ComponentIds.PremiumError,
        ComponentIds.PremiumErrorText,
        ComponentIds.PremiumErrorContainer,
        ComponentIds.MultiStateBox,
        ComponentIds.FormStateBox,
        ComponentIds.RootBox,
        ComponentIds.EmptyStateBox,
        ComponentIds.Amount,
        ComponentIds.Frequency,
        ComponentIds.EmptyStateBody,
        ComponentIds.EmptyStateTitle,
      ]);
      setComponentsAsUnselectable(controllerBuilder, EmptyStateComponentIds);
      controllerBuilder.configureConnectedComponents(
        getRole(ComponentIds.DonateButton),
        (innerWidgetBuilder: ConnectedComponentsBuilder) => {
          innerWidgetBuilder.behavior().set({ preventHide: true });
          innerWidgetBuilder
            .set({
              displayName: t.donationForm.donateButton.displayName(),
            })
            .gfpp()
            .set('mainAction2', {
              label: t.donationForm.donateButton.gfpp.design(),
              onClick: ({ detail: { componentRef } }) => {
                console.log('####', componentRef);
                void editorSDK.editor.openNativeComponentPanel('', 'design', {
                  componentRef,
                });
              },
            })
            .set('add', { behavior: 'HIDE' })
            .set('connect', { behavior: 'HIDE' })
            .set('link', { behavior: 'HIDE' })
            .set('help', { id: helpIds.DONATE_BUTTON })
            .set('animation', { behavior: 'HIDE' })
            .set('layout', { behavior: 'HIDE' });
          innerWidgetBuilder
            .panel<'StylableButton'>('settings')
            .configureControls({
              link: {
                hidden: true,
              },
              icon: {
                hidden: true,
              },
            } as any); // https://jira.wixpress.com/browse/ECL-5987
        },
      );
      controllerBuilder.configureWidgetDesign((widgetBuilder) =>
        donationFormDesignBuilderFn(widgetBuilder, t),
      );
    })
    .configureManagementActions((managementActionsBuilder) => {
      managementActionsBuilder.mainActions().addAction({
        title: t.myBusinessPanel.manage.manageCamapigns.button(),
        icon: 'settingsAction',
        onClick: () => openManageCampaignsDashboard(editorSDK),
      });
      managementActionsBuilder.customActions().addAction({
        title: t.myBusinessPanel.manage.addNewForm.button(),
        icon: 'addAction',
        actionId: PlatformPanelActionIds.DONATION_ADD_PANEL_DEEPLINK,
        type: 'dashboard',
      });
      managementActionsBuilder.upgradeAction().set({
        upgradeType: 'SITE_UPGRADE',
        upgradeText: t.myBusinessPanel.upgradeBannertext(),
        upgradeLinkText: t.myBusinessPanel.upgradeBannerlink(),
      });
    })
    .build();
  return {
    ...manifest,
  };
};

const hideSeoA11y = (componentBuilder: ConnectedComponentsBuilder) => {
  componentBuilder.panel<'WRichText'>('settings').configureSections({
    seoA11y: {
      hidden: true,
    },
    verticalText: {
      hidden: false,
    },
  });
};

const getRole = (wixCodeId: string): string => {
  return wixCodeId.substring(1);
};
