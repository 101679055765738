import { ComponentRef, EditorSDK } from '@wix/platform-editor-sdk';
import { getElementsData } from '@/editor-app/panels/getElementsData';
import { initLocaleKeys } from '@/common/utils/locale';
import { FlowAPI } from '@wix/yoshi-flow-editor';
import { showHideDonationsWidgetElements } from '@wix/bi-logger-wixdonations/v2';

export const openShowHidePanel = async (
  editorSDK: EditorSDK,
  widgetRef: ComponentRef,
  flowAPI: FlowAPI,
): Promise<void> => {
  const t = initLocaleKeys(flowAPI.translations.i18n);
  const getCollapsedRefComponentByRole = async (role) => {
    const [widgetRefHost] = await editorSDK.components.getAncestors('token', {
      componentRef: widgetRef,
    });
    const collapsedRefComponents =
      await editorSDK.components.refComponents.getCollapsedRefComponents(
        'token',
        {
          componentRef: widgetRefHost,
          // @ts-expect-error temp until types are GAed
          includeInnerCollapsed: true,
        },
      );
    const collapsedRefComponent = collapsedRefComponents.filter(
      (comp) => comp.role === role,
    );
    return collapsedRefComponent[0].componentRef;
  };

  const addComponentHandler = async ({ role }) => {
    const componentRef = await getCollapsedRefComponentByRole(role);
    return showComp(componentRef);
  };

  const showComp = async (componentRef) => {
    flowAPI.bi?.report(
      showHideDonationsWidgetElements({
        element: componentRef.role,
        show: false,
      }),
    );
    await editorSDK.components.refComponents.expandReferredComponent('token', {
      componentRef,
    });
  };

  const getWidgetCompRef = async (componentRef) => {
    const type = await editorSDK.components.getType('token', { componentRef });
    return type.includes('AppWidget')
      ? (await editorSDK.components.getAncestors('t', { componentRef }))[0]
      : /* istanbul ignore next reason: we don't hide whole widget */ componentRef;
  };

  const removeComponentHandler = async (compRef) => {
    const compToHide = await getWidgetCompRef(compRef);
    return hideComp(compToHide);
  };

  const hideComp = async (componentRef) => {
    flowAPI.bi?.report(
      showHideDonationsWidgetElements({
        element: componentRef.role,
        show: false,
      }),
    );
    await editorSDK.components.refComponents.collapseReferredComponent(
      'token',
      {
        componentRef,
      },
    );
  };

  return editorSDK.editor.openElementsPanel('t', {
    widgetRef,
    elementsData: getElementsData(t),
    categoriesData: [],
    addComponentHandler,
    removeComponentHandler,
  });
};
