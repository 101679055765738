import { EditorSDK, EventType } from '@wix/platform-editor-sdk';

export const registerDashboardClosedEvent = (
  editorSDK: EditorSDK,
): Promise<void> =>
  editorSDK.addEventListener(EventType.appVisitedInDashboard, () => {
    return void editorSDK.application.livePreview.refresh('token', {
      shouldFetchData: false,
      source: 'AFTER_DB_CHANGE',
    });
  });

export const registerWidgetResetEvent = (editorSDK: EditorSDK): Promise<void> =>
  editorSDK.addEventListener(EventType.resetWidgetOverrides, () => {
    return void editorSDK.application.livePreview.refresh('token', {
      shouldFetchData: false,
      source: 'AFTER_DB_CHANGE',
    });
  });
