import { ElementData } from '@wix/platform-editor-sdk';
import { ComponentIds as DonationFormComponentIds } from '@/components/DonationForm/constants';
import { getRole } from '@/common/utils/getRole';
import { ILocaleKeys } from '@/locale-keys';
import { ComponentIds as FrequencyWidgetComponentIds } from '@/components/FrequencyWidget/constants';
import { ComponentIds as AmountWidgetComponentIds } from '@/components/AmountWidget/constants';
import { ComponentIds as NoteWidgetComponentIds } from '@/components/NoteWidget/constants';

export const getElementsData = (t: ILocaleKeys): ElementData[] => {
  return [
    {
      label: t.donationForm.formWidget.elements.title.label(),
      identifier: { role: getRole(DonationFormComponentIds.Title) },
      index: 0,
    },
    {
      label: t.donationForm.formWidget.elements.description.label(),
      identifier: { role: getRole(DonationFormComponentIds.Description) },
      index: 1,
    },
    {
      label: t.donationForm.formWidget.elements.frequency.label(),
      identifier: { role: getRole(FrequencyWidgetComponentIds.Label) },
      index: 2,
    },
    {
      label: t.donationForm.formWidget.elements.amount.label(),
      identifier: { role: getRole(AmountWidgetComponentIds.Label) },
      index: 3,
    },
    {
      label: t.donationForm.formWidget.elements.donorNote.label(),
      identifier: { role: getRole(NoteWidgetComponentIds.Label) },
      index: 4,
    },
  ];
};
