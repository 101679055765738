import {
  createBlocksModel,
  WidgetPropertyType,
} from '@wix/yoshi-flow-editor/blocks';

export default createBlocksModel({
  widgetName: 'DonationForm',
  props: {
    campaignId: {
      type: WidgetPropertyType.STRING,
    },
    frequencyOptions: {
      type: undefined as any,
      defaultValue: [],
    },
    amountOptions: {
      type: undefined as any,
      defaultValue: [],
    },
    amountDefaultValue: {
      type: WidgetPropertyType.STRING,
      defaultValue: undefined,
    },
    frequencyDefaultValue: {
      type: WidgetPropertyType.STRING,
      defaultValue: undefined,
    },
  },
  methods: {},
  events: {},
});
