import { RootStore } from '@/components/DonationForm/types';

export type ReportFedopsInteractionFn = (
  interaction: string,
  rootStore: RootStore,
  fn: () => unknown,
) => Promise<void>;

export enum FedopsInteractions {
  DonationFormSubmit = 'donation-form-submit',
  AddDonationFormWidget = 'add-donation-form-widget',
  AddThankYouPage = 'add-thank-you-page',
  AddThankYouPageCreatePage = 'add-thank-you-page-create-page',
  AddThankYouPageWidgetStudio = 'add-thank-you-page-widget-studio',
  AddThankYouPageWidgetClassic = 'add-thank-you-page-widget-classic',
}

export const reportFedopsInteraction: ReportFedopsInteractionFn = async (
  interaction,
  rootStore,
  fn,
): Promise<any> => {
  rootStore.fedops.interactionStarted(interaction);
  const ret = await fn();
  rootStore.fedops.interactionEnded(interaction);
  return ret;
};
