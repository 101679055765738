import { ComponentIds as DonationFromWidgetComponentIds } from '@/components/DonationForm/constants';
import { WidgetDesignBuilderFn } from '@/editor-app/types';
import { getRole } from '@/common/utils/getRole';
import { helpIds } from '@/common/constants/gfpp';

export const donationFormDesignBuilderFn: WidgetDesignBuilderFn = (
  widgetDesignBuilder,
  t,
): void => {
  widgetDesignBuilder.set({
    title: t.donationForm.formWidget.design.panelHeader(),
    customHelpId: helpIds.DONATION_FORM_DESIGN,
  });

  const widgetDesignTabsBuilder = widgetDesignBuilder.tabs();

  widgetDesignTabsBuilder.addTab((tabBuilder) => {
    tabBuilder
      .set({
        label: t.donationForm.formWidget.design.background.sectionName(),
      })
      .groups()
      .set({
        roles: [DonationFromWidgetComponentIds.FormStateBox].map(getRole),
      });
  });
};
