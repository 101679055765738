import { i18n } from 'i18next';
import { LocaleKeys } from '@/locale-keys';
import { IWixAPI } from '@wix/yoshi-flow-editor';

const DEFAULT_LOCALE = 'en-US';

export const getLocale = ({ wixCodeApi }: { wixCodeApi: IWixAPI }) => {
  return wixCodeApi.site.regionalSettings ?? DEFAULT_LOCALE;
};

export const initLocaleKeys = (i18nInstance: i18n) => {
  return LocaleKeys(i18nInstance.t.bind(i18nInstance));
};

export const initLocaleKeysWithTranslationFn = (fn: any) => {
  return LocaleKeys(fn);
};
